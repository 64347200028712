<template>
  <div class='app'>
    
    <!-- hero -->
    <div class='section'>
      <Hero />
    </div>
    
    <!-- nothing new or unusual -->
    <div class='section'>
      <div class='column column-text'>
        <div class='body'>
          <h2 class='body__subtitle'>Nothing New or Unusual</h2>
          <p class='body__text'>There's this map that has stuck with me since I first saw it. It's a bright red map of Japan with black lines jutting out from all sides. At the end of each black line is the name of a Japanese city, a U.S. city, and a number.</p>
          <p class='body__text'>The map was published by the U.S. government shortly after WWII to demonstrate the scale of the U.S. firebombing of Japan. Before dropping atomic bombs on Hiroshima and Nagasaki, the U.S. military dropped thousands of incendiary explosives on Japanese cities. These explosives were designed to start fires. At the time, Japanese cities were built primarily out of wood.</p>
          <p class='body__text'>On the map, each black line points to a major industrial city in Japan. Each of these cities is compared to a similarly sized U.S. city for reference. Each number indicates the percent of the city destroyed by incendiary explosives.</p>
        </div>
      </div>

      <div class='figure'>
        <div class='column column-text'>
          <div class='body'>
            <h3 class='figure__title'>The Map</h3>
            <hr>
          </div>
        </div>

        <div class='body'>
          <picture>
            <source media="(max-width: 450px)" :srcset='mapUrl["450"]' >
            <source media="(max-width: 1000px)" :srcset='mapUrl["1000"]' >
            <source media="(max-width: 1400px)" :srcset='mapUrl["1400"]' >
            <source media="(max-width: 1600px)" :srcset='mapUrl["1600"]' >
            <source media="(max-width: 1920px)" :srcset='mapUrl["1920"]' >
            <source media="(min-width: 1920px)" :srcset='mapUrl["fullsize"]' >

            <img class='is-full-width' :src='mapUrl["fullsize"]' alt='Map of Japanese Cities targeted by U.S. Firebombing Missions'>
          </picture>
        </div>
      </div>

      <div v-if='isMobile' class='figure'>
        <div class='column column-text'>
          <div class='body'>
            <h3 class='figure__title'>Map Detail</h3>
            <hr>
          </div>
        </div>
        <div class='body'>
          <img class='is-full-width' :src='mapReliefUrl' alt='Zoom of map of Japanese Cities targeted by U.S. Firebombing Missions'>
        </div>
      </div>


      <div class='column column-text'>
        <div class='body'>
          <p class='body__text'>This map seems designed to disorient and overwhelm. Japan is unnaturally rotated clockwise to fit onto a 2-page magazine spread. The heavy red and black ink contrasts harshly with the white background. The black lines are placed at seemingly arbitrary angles, criss-crossing the country, and the city names haphazardly surround the map, making it difficult to know where to look or which numbers to read first.</p>
          <p class='body__text'>And in some sense, that's the point. It doesn't matter where you look. The destruction is everywhere.</p>
        </div>
      </div>
    </div>

    <div class='section'>
      <div class='column column-text'>
        <div class='body'>
          <h2 class='body__subtitle'>As if the world had ended</h2>
          <p class='body__text'>By design, this map emphasizes the breadth of the U.S. firebombing campaign, the sheer number of cities affected across the whole of Japan. But something is lost in its bird's nest of names and numbers. The map shows how widespread the bombings were, but it does not show what those bombings looked like. You can scan from city to city, but you cannot see the patterns across them.</p>
          <p class='body__text'>Here is the same data visualized slightly differently.</p>
        </div>
      </div>

      <div class='figure'>
        <div class='column column-text'>
          <div class='body'>
            <h3 class='figure__title'><span>Percent of city destroyed </span><br v-if='isMobile'><span>by incendiary explosives</span></h3>
          </div>
        </div>
        
        <div class='column column-table'>
          <div class='body'>
            <hr>
            <BarChartCities :data='data' :isMobile='isMobile' :resize='resize'/>
          </div>
        </div>
      </div>

      <div class='column column-text'>
        <div class='body'>
          <p class='body__text'>This table uses the same data, harsh colors, and cramped text of the original, yet the emphasis is decidedly different. The first line shows Nishinomiya, the city on the map <i>least</i> affected by the U.S. firebombing, with over 12% of the city lost to fires. As the table continues, the numbers grow. One third destroyed. Two thirds destroyed. Ninety-six percent destroyed. City after city after city. Framing the data in this way reveals the scale of devestation hidden in the original map.</p> 
        </div>
      </div>
    </div>

    <!-- we are not just statistics -->
    <div class='section'>
      <div class='column column-text'>
        <div class='body'>
          <h2 class='body__subtitle'>Planes Filled the Sky Like Dragonflies</h2>
          <p class='body__text'>Both the original map and the redesigned table tell different stories with the same data. Neither is more or less accurate; rather, each provides a perspective. Shine a spotlight in one place and you inevitably cast a shadow somewhere else.</p>
          <p class='body__text'>Here, that shadow falls on millions of Japanese civilians living through the war. The original map includes nearly 70 Japanese cities, each with its own story of profound loss. It's worth taking a moment to consider what just one of those stories looks like. 
          
          <span class='flex'>
            <img class='figure__inline' :src='tokyoLabel' alt='Tokyo 39.9% (New York)'>
          </span>

          On the night of March 9, 1945 the U.S. sent 334 B-29 bombers armed with over 1,665 tons of incendiary explosives to Tokyo. Nearly 16 square miles of the city were destroyed. Approximately 100,000 civilians were killed and 1,000,000 more left homeless. More lives were lost in one night than would be lost during the atomic bombing of Hiroshima or Nagasaki.</p>
          <p class='body__text'>The city would be bombed again on April 1st, April 4th, April 7th, April 13th, April 15th, April 24th, May 23rd, and May 25th.</p>
        </div>
      </div>


      <!-- IMAGES -->
      <div class='figure'>
        <div class='column column-text'>
          <div class='body'>
            <h3 class='figure__title'><span>B-29 Bombers Releasing </span><br v-if='isMobile'><span>Incendiary Explosives</span></h3>
            <hr>
          </div>
        </div>

        <div v-if='!isMobile'>
          <div class='flex flex-direction-row'>
            <img class='figure__side-by-side' :src="b29Url['960']" />
            <img class='figure__side-by-side' :src="kobeUrl['960']" />
          </div>
        </div>

        <div v-else>
          <img class='figure__mobile' :src="b29Url['500']" />
        </div>
      </div>

      <div v-if='isMobile' class='figure'>
        <div class='column column-text'>
          <div class='body'>
            <h3 class='figure__title'>Air Raid on Kobe</h3>
            <hr>
          </div>
        </div>
        <img class='figure__mobile' :src="kobeUrl['500']" />
      </div>

      <div class='figure'>
        <div class='column column-text'>
          <div class='body'>
            <h3 class='figure__title'>Aftermath of the Tokyo Air Raids</h3>
            <hr>
          </div>
        </div>

        <picture>
          <source media="(max-width: 450px)" :srcset='tokyoMapUrl["450"]' >
          <source media="(max-width: 1000px)" :srcset='tokyoMapUrl["1000"]' >
          <source media="(max-width: 1400px)" :srcset='tokyoMapUrl["1400"]' >
          <source media="(max-width: 1600px)" :srcset='tokyoMapUrl["1600"]' >
          <source media="(min-width: 1600px)" :srcset='tokyoMapUrl["fullsize"]' >

          <img class='figure__tokyo is-full-width' :src='tokyoMapUrl["fullsize"]' alt='Image of Tokyo showing the aftermath of the firebombing.'>
        </picture>

      </div>

    </div>

    <!-- planes filled the sky like dragonflies -->
    <div class='section'>
      <div class='column column-text'>
        <div class='body'>
          <h2 class='body__subtitle'>We Are Not Just Statistics</h2>
          <p class='body__text'>In 2013 the U.S. government released a public database detailing every U.S. bombing mission flown during WWII. With this data we can reconstruct a timeline of the individual air raids that led to the destruction of the cities shown on the original map.</p>
          <p class='body__text'>Here is a chart showing the total tons of incendiary explosives dropped by U.S. bombers on Japanese cities <i>each day</i> of 1944 and 1945. </p>
        </div>
      </div>

      <div class='figure'>
        <div class='column column-text'>
          <div class='body'>
            <h3 class='figure__title tons'><span>Tons of Incendiary Explosives </span><br v-if='isMobile'><span>Dropped Per Day</span></h3>
            <hr>
          </div>
        </div>

        <div class='column column-table'>
          <div class='body'>
            <BarChartTons :data='data' :isMobile='isMobile' :resize='resize' :dataIsReady='dataIsReady' />
          </div>
        </div>
      </div>

      <div class='column column-text'>
        <div class='body'>
          <p class='body__text'>In the early days of the Pacific, incendiary explosives were rarely used. By the end of 1944, they became commonplace, but with comparatively small payloads. Then tactics changed.</p>
          <p class='body__text'>The March 9th, 1945 bombing of Tokyo began a series of large-scale firebombing raids which would continue up until (and several days after) the bombing of Hiroshima and Nagasaki on August 6th and August 9th, 1945.</p>
          <p class='body__text'>Here is an animation showing the location, size, and frequency of those firebombing raids. Each red circle represents a single mission (each mission consists of multiple planes). The size of the circle represents the total tons of incendiary explosives used.</p> 
        </div>
      </div>

      <div class='figure figure__map-circles'>
        <div class='column column-table'>
          <div class='body'>
            <h3 class='figure__title'>Daily Firebombing Missions in 1945</h3>
            <hr>
          </div>
        </div>

        <div class='column column-table'>
          <div class='body'>            
            <Map :data='data' :isMobile='isMobile' :resize='resize' :dataIsReady='dataIsReady' plotType='circles' :showControls='true' :animate='true' />
          </div>
        </div>
      </div>

      <div class='column column-text'>
        <div class='body'>
          <p class='body__text'>By the end of the war, the bombing missions are so extensive that it's difficult to differentiate them on the map. Some hotspots stand out as solid red blobs, but nearly the entire country is covered in shades of red.</p>
          <p class='body__text'>To see through this patchwork, we can  rearrange the data again. Instead of looking at individual missions, we can look at the cumulative impact of those missions at each location.</p>
          <p class='body__text'>This chart shows the cumulative tons of incendiary explosives dropped at each recorded latitude and longitude over the course of the war. Each bar represents a unique target. The size and color of the bars correspond to the total tons of incendiary explosives dropped on that target.</p>
          <p></p>
        </div>
      </div>
  
      <div class='figure'>
        <div class='column column-table'>
          <div class='body'>
            <h3 class='figure__title'><span>Cumulative Tons </span><br v-if='isMobile'><span>of Incendiary Explosives</span></h3>
            <hr>
          </div>
        </div>

        <div class='column column-table'>
          <div class='body'>  
            <Map :data='data' :isMobile='isMobile' :resize='resize' :dataIsReady='dataIsReady' plotType='bars' :showControls='false' :animate='false' />
          </div>
        </div>
      </div>

      <div class='column column-text'>
        <div class='body'>
          <p class='body__text'>With this map, we still see the breadth of the firebombing campaign, with bars of all sizes dotting the countryside. However, unlike the other maps, this clearly identifies the primary targets of the campaign. A mountain of bluish-purple bars cluster around Tokyo and Osaka, whose red peaks tower over all the rest.</p>
        </div>
      </div>
    </div>

    <!-- ending -->
    <div class='section'>
      <div class='column column-text'>
        <div class='body'>
          <h2 class='body__subtitle'>They were all individuals</h2>
          <p class='body__text'>Together, these maps summarize over 1,100 U.S. firebombing missions, consisting of more than 16,000 aircraft dropping more than 97,000 tons of incendiary explosives.</p>
          <p class='body__text'>Like all summaries, this one is necessarily incomplete. The story of the U.S. firebombing of Japan and its context in the larger history of WWII is much larger and more nuanced than what is presented here. This site narrowly focuses on one piece of that story.</p>
          <p class='body__text'>To read more about the history of these missions, including survivor accounts from both of U.S. servicemen and Japanese civilians, see the following articles. The title of this site and each of the subtitles for the site are direct quotes from survivors featured in these stories.
            <ul class='body__list'>
              <li><a class='body__link' href='https://www.nytimes.com/2020/03/09/magazine/the-man-who-wont-let-the-world-forget-the-firebombing-of-tokyo.html?action=click&module=RelatedLinks&pgtype=Article'>The Man Who Won’t Let the World Forget the Firebombing of Tokyo</a></li>
              <li><a class='body__link' href='https://www.independent.co.uk/news/world/asia/tokyo-fire-bombing-70th-anniversary-survivors-beg-japan-remember-forgotten-100-000-10096651.html'>Tokyo fire bombing 70th anniversary: Survivors beg Japan to remember the forgotten 100,000</a></li>
              <li><a class='body__link' href='https://apnews.com/article/c496a7266c32499ebd0c75b7abb33d3d'>Tokyo remembers devastating, overlooked WWII firebombings</a></li>
              <li><a class='body__link' href='https://www.nytimes.com/2020/03/09/magazine/we-hated-what-we-were-doing-veterans-recall-firebombing-japan.html'>‘We Hated What We Were Doing’: Veterans Recall Firebombing Japan</a></li>
            </ul>
          </p>
        </div>
      </div>
    </div>

    <!-- notes -->
    <div class='section footnote'>
      <div class='column column-text'>
        <div class='body'>
          <hr>
          <h2 class='body__subtitle is-size-3'>Notes</h2>
          <p class='body__footnote is-size-4'>The original map was published in <a class='footnote__link' href='https://babel.hathitrust.org/cgi/pt?id=mdp.39015005496537&view=1up&seq=42'>Third Report of the Commanding General of the Army Air Forces to the Secretary of War</a>.</p>
          <p class='body__footnote is-size-4'>Data for the redesigned table, including historical U.S. city populations, are from <a class='footnote__link' href='http://blog.nuclearsecrecy.com/2014/03/12/firebombs-usa/'>Alex Wellerstein's blog</a>. Check out his <a class='footnote__link' href='http://blog.nuclearsecrecy.com/misc/firebombsusa/'>visualization</a> of this data.</p>
          <p class='body__footnote is-size-4'>Data for individual bombing missions from the <a class='footnote__link' href='https://data.world/datamil/world-war-ii-thor-data'>THOR dataset</a>.</p>
          <p class='body__footnote is-size-4'>Photographs of B-29 bombers from <a href='https://www.airspacemag.com/military-aviation/final-act-180954078/' class='footnote__link'>Air & Space Magazine</a>, Kobe air raid from <a href='https://upload.wikimedia.org/wikipedia/commons/5/5f/American_bombs_falling_on_Kobe.jpg' class='footnote__link'>Wikipedia</a>, and Tokyo post-bombing from <a href='https://www.nytimes.com/2020/03/09/magazine/we-hated-what-we-were-doing-veterans-recall-firebombing-japan.html' class='footnote__link'>New York Times Magazine</a>.</p>
          <p class='body__footnote is-size-4'>Data processed in Python using Pandas. Site built with Vue.js, d3.js, and Chart.js.</p>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class='footer flex'>
      <div>designed and built by <a href='https://againwewander.com' class='footer__link'>Again We Wander</a></div>
    </div>

  </div>
</template>


<script>
  import { json, csv } from 'd3-fetch';
  import debounce from 'lodash.debounce';
  import * as topojson from 'topojson-client';

  import { initHeroScrollTrigger } from './js/scroll.js';

  import Hero from './components/Hero.vue';
  import BarChartCities from './components/BarChartCities.vue';
  import BarChartTons from './components/BarChartTons.vue';
  import Map from './components/Map.vue';

  export default {
    components: {
      Hero,
      BarChartCities,
      BarChartTons,
      Map
    },
    data() {
      return {
        mapUrl: {
          '450': process.env.VUE_APP_MAP_450,
          '1000': process.env.VUE_APP_MAP_1000,
          '1400': process.env.VUE_APP_MAP_1400,
          '1600': process.env.VUE_APP_MAP_1600,
          '1920': process.env.VUE_APP_MAP_1920,
          'fullsize': process.env.VUE_APP_MAP_FULLSIZE
        },
        mapReliefUrl: process.env.VUE_APP_MAPRELIEF,
        tokyoLabel: process.env.VUE_APP_TOKYOLABEL,
        tokyoMapUrl: {
          '450': process.env.VUE_APP_TOKYOMAP_450,
          '1000': process.env.VUE_APP_TOKYOMAP_1000,
          '1400': process.env.VUE_APP_TOKYOMAP_1400,
          '1600': process.env.VUE_APP_TOKYOMAP_1600,
          'fullsize': process.env.VUE_APP_TOKYOMAP_FULLSIZE
        },
        b29Url: {
          '500': process.env.VUE_APP_B29_500,
          '960': process.env.VUE_APP_B29_960
        },
        kobeUrl: {
          '500': process.env.VUE_APP_KOBE_500,
          '960': process.env.VUE_APP_KOBE_960
        },

        data: {
          countries: [],
          thor: [],
          arnold: []
        },
        dataIsReady: false,
        resize: 0,
        isMobile: false
      }
    },
    created() {
      window.addEventListener('resize', debounce(this.handleResize, 100));
      // window.addEventListener('scroll', debounce(this.handleScroll, 100))
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
      // window.removeEventListener('scroll', this.handleScroll);
    },
    async mounted() {
      initHeroScrollTrigger();
      this.handleResize();
      await this.getData();
    },
    methods: {
      handleResize() {  
        // set a resize flag that will always change
        this.resize = Math.random();
        
        // check for mobile
        const width = window.innerWidth;
        this.isMobile = width <= 768;
      },
      getData() {
        return Promise.all([this.getCountries(), this.getThor(), this.getArnold()])
         .then(() => {
            this.dataIsReady = true;
          })
      },
      async getCountries() {
        const uri = 'https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json';
        const jsonData = await json(uri)
        this.data.countries = topojson.feature(jsonData, jsonData.objects.countries).features;

        // drop antarctica
        this.data.countries = this.data.countries.filter(x => {
          return x.properties.name === 'Japan'
        }) 
      },
      async getThor() {
        const uri = 'https://firebasestorage.googleapis.com/v0/b/japan-firebomb.appspot.com/o/thor_data.csv?alt=media&token=11c59d22-8ed9-48cb-9547-c037d1f77f88'
        const thorData = await csv(uri, d => {
          return {
            // msnDate: new Date(d.msndate),
            msnDate: new Date(d.msndate),
            destLat: +d.latitude,
            destLon: +d.longitude,
            destId: d.dest_id,
            tons: +d.tons_of_ic
          }
        });

        // sort
        thorData.sort((a, b) => {
          if (a.destLat < b.destLat) return 1;
          if (b.destLat > a.destLat) return -1;
          return 0
        });
        this.data.thor = thorData;
      },
      async getArnold() {
        const uri = 'https://firebasestorage.googleapis.com/v0/b/japan-firebomb.appspot.com/o/arnold_data.csv?alt=media&token=05f66288-1d35-4e8b-859b-9c5b5f4cbddb'
        const arnoldData = await csv(uri, d => {
          return {
            japanCity: d.jcity,
            usCity: d.uscity,
            pop: +d.uspop,
            pct: +d.destroyed,
          }
        });

        // sort
        arnoldData.sort((a, b) => (a.pct > b.pct) ? 1 : -1);

        this.data.arnold = arnoldData;
      }
    }
  }
</script>