import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


export function initHeroScrollTrigger() {
  const el = document.querySelector('.hero__flag');
  gsap.from(el, {
    scale: 0,
    duration: 5,
    ease: "power1.inOut",
    delay: 0,
  });
}

