<template>
  <div class='bar-chart-tons'>
    <BarChart 
      v-if='!isMobile & dataIsReady'
      :chartData="chartData" 
      :chartOptions="chartOptions" 
    />

    <HorizontalBarChart 
      v-if='isMobile & dataIsReady'
      :chartData="chartData" 
      :chartOptions="chartOptions" 
    />
  </div>
</template>

<script>
  import BarChart from './BarChart.js';
  import HorizontalBarChart from './HorizontalBarChart.js';

  import { fmtNum, getDateArray } from '../js/helpers.js';

  import gsap from 'gsap';
  import ScrollTrigger from 'gsap/ScrollTrigger';
  gsap.registerPlugin(ScrollTrigger);

  export default {
    components: {
      BarChart,
      HorizontalBarChart
    },
    props: {
      data: Object,
      resize: Number,
      isMobile: Boolean,
      dataIsReady: Boolean
    },
    data() {
      return {
        chartData: {
          labels: [],
          datasets: []
        },
        chartOptionsRoot: {
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          },
          responsive: true,
          maintainAspectRatio: false
        },

        unfilteredBarDataArray: [],
      }
    },
    computed: {
      chartOptions() {
        // extend the current ChartOptions
        let options = Object.assign({}, this.chartOptionsRoot);

        // this is the setting for the Values
        const valueAxis = [{
          gridLines: { display: true },
          position: this.isMobile ? 'bottom' : 'left',
          ticks: {
            fontFamily: 'Oswald',
            fontSize: 16,
            fontColor: 'black',
            min: 0,
            max: 5000,
            beginAtZero: true,
            stepSize: this.isMobile ? 2500 : 1000,
            callback: function(value) {
              // return value === 0 ? '' : fmtNum(value)
              return fmtNum(value)
            }
          }
        }]

        // this is the setting for the Categories
        const categoryAxis = [{
          gridLines: { 
            display: true,
            offsetGridLines: false
          },
          type: "time",
          time: {
            unit: 'month',
            round: 'day',
          },
          ticks: {
            fontFamily: 'Oswald',
            fontSize: 16,
            fontColor: 'black',
            reverse: this.isMobile,
            callback: function(value) { 
              return value.slice(0,3) ;
              // console.log(value, '----', new Date(value))
              // const date = new Date(value);
              // return date.toLocaleDateString('en-US', {month: 'short'})
            }
          }
        }, {
          gridLines: { 
            display: false,
            offsetGridLines: true
          },
          type: "time",
          time: {
            unit: 'year',
            round: 'day',
          },
          ticks: {
            fontFamily: 'Oswald',
            fontSize: 16,
            fontColor: 'black',
            reverse: this.isMobile,
            callback: function(value) { 
              return value
              // const date = new Date(value);
              // return parseInt(date.toLocaleDateString('en-US', {year: 'numeric'})) + 1
            }
          }
        }]


        // add the settings to the options object
        options.scales = {
          xAxes: this.isMobile ? valueAxis : categoryAxis,
          yAxes: this.isMobile ? categoryAxis : valueAxis
        }
        
        // send it back
        return options
      }
    },
    watch: {
      dataIsReady() {
        this.setDatasets();
        // this.initChartScrollTrigger();
      },
    },
    methods: {
      initChartScrollTrigger() {
        const el = document.querySelector('.bar-chart-tons');
        ScrollTrigger.create({
          trigger: el,
          start: 'top 50%',
          onEnter: () => {
            this.filterData(1);
          }
        })
      },
      filterData(pct) {
        let chartData = Object.assign([], this.chartData);
        let filteredData = [];
        const numRecords = Math.floor(this.unfilteredBarDataArray.length * pct);
        for (let i=0; i<numRecords; i++) {
          filteredData.push(this.unfilteredBarDataArray[i])
        }
        chartData.datasets[0].data = filteredData;
        this.chartData = chartData;
      },
      setDatasets() {
        // get sum by day

        // create list of all possible days
        const startDate = new Date('1944/01/01');
        const endDate = new Date('1945/09/02');
        const allPossibleDays = getDateArray(startDate, endDate);
        const allPossibleDayStrings = allPossibleDays.map( x => {
          const year = x.getFullYear();
          const month = x.getMonth() + 1;
          const day = x.getDate();
          return year + '-' + month + '-' + day;
        })

        // set total for  all days = 0
        let tonsPerDay = {};
        allPossibleDayStrings.forEach( day => { tonsPerDay[day] = 0 });

        // loop through real data to create cumulative total by day
        this.data.thor.forEach( x => {
          const year = x.msnDate.getFullYear();
          const month = x.msnDate.getMonth() + 1;
          const day = x.msnDate.getDate();
          const key = year + '-' + month + '-' + day;
          if (Object.keys(tonsPerDay).includes(key)) {
            tonsPerDay[key] += x.tons;
          }
        })
        this.unfilteredBarDataArray = Object.values(tonsPerDay);

        // fmt for chartjs
        this.chartData.datasets = [{
          data: this.unfilteredBarDataArray, // start with an empty plot
          backgroundColor: '#dc3a16',
          barPercentage: 1,
          barThickness: 4
        }];
        this.chartData.labels = allPossibleDays;
      },
    }
  }
</script>
