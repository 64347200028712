<template>
  <div class='map flex'>


    <!-- map -->
    <div class='map__container '>
      <canvas class='map__canvas' />
    </div>

    <!-- slider -->
    <div v-if='showControls' class='map__slider__container flex'>

      <!-- range slider -->
      <input type="range" min="0" :max="allPossibleDays.length-1" v-model='selectedNumber' class="map__slider" :style='{width: "100%"}' >
      

      <!-- legend for months-->
      <div class='map__slider__label__container flex flex-direction-row'>
        <p v-for='(day, i) in allPossibleMonthYears' :key='"day" + i' 
           class='map__slider__label'
           :class='{"is-red": (day.getFullYear() === selectedYear) & (new Date(day).toLocaleDateString("en-US", {month: "short"}) === selectedMonth) }' >
              {{ new Date(day).toLocaleDateString('en-US', {month: 'short'}) }}
        </p>
      </div>


      <!-- legend for years -->
      <div class='map__slider__label__container flex flex-left flex-direction-row flex-align-start'>
        <p class='map__slider__label' :style='{width: 100/allPossibleMonthYears.length + "%"}'>1945</p>

      </div>
    </div>


  </div>
</template>


<script>
  import { plot } from '../js/map.js';
  import { getDateArray } from '../js/helpers.js';
  import { geoPath, geoMercator } from 'd3-geo';


  import gsap from 'gsap';
  import ScrollTrigger from 'gsap/ScrollTrigger';
  gsap.registerPlugin(ScrollTrigger);


  export default {
    props: {
      data: Object,
      resize: Number,
      isMobile: Boolean,
      dataIsReady: Boolean,
      plotType: String,
      showControls: Boolean,
      animate: Boolean
    },
    data() {
      return {
        allPossibleDays: [],
        allPossibleMonthYears: [],
        selectedNumber: 0,

        width: null,
        height: null,

        canvas: null,
        context: null,

        projection: null,
        path: null,

        pctComplete: 0,

        filteredData: {},

        gsapObj: null
      }
    },
    created() {
      // get list of dates
      const startDate = '1945/01/01'; 
      const endDate = new Date('1945/08/31');
      this.allPossibleDays = getDateArray(startDate, endDate);

      this.allPossibleMonthYears = this.allPossibleDays.filter( x => {
        return x.getDate() === 1; 
      })

    },
    watch: {
      resize() {
        this.handleResize();
      },
      dataIsReady() {
        // init the scrolltrigger if you're animating
        if (this.animate) {
          this.initMapScrollTrigger();
        }

        // if you're going to animate this, start at first date
        // if you're NOT animating this, start at the last date
        let date = new Date();
        if (this.animate) {
          date = this.selectedDate;
        } else {
          date = this.allPossibleDays[this.allPossibleDays.length - 1];
        }

        // plotMapCircles the map with just the country
        this.filterDataByDate(date);
        this.plotMap();
      },
      selectedNumber() {
        this.filterDataByDate(this.selectedDate);
        this.plotMap();
      },
    },
    computed: {
      selectedDate() {
        return this.allPossibleDays[this.selectedNumber];
      },
      selectedMonth() {
        return this.selectedDate.toLocaleDateString('en-US', {month: 'short'})
      },
      selectedYear() {
        return this.selectedDate.getFullYear();
      },
    },
    methods: {
      initMapScrollTrigger() {
        let config = {day: 0};
        const el = document.querySelector('.figure__map-' + this.plotType)
        this.gsapObj = gsap.to(config, {
          day: this.allPossibleDays.length - 1,
          duration: 10,
          scrollTrigger: {
            // markers: true,
            trigger: el,
            start: 'top top',
            end: 'bottom 50%',
            ease: 'none',
            pin: true,
            anticipatePin: 1,
            onRefresh: (self) => {
              if (self.isActive === true) {
                self.progress += .0001;
              }
            }
          },
          onUpdate: () => {
            this.selectedNumber = Math.floor(config.day);
          }
        })
      },
      handleResize() {
        // get the canvas
        this.canvas = this.$el.querySelector('.map__canvas');
        this.context = this.canvas.getContext('2d');

        // update canvas resolution
        const resolution = window.devicePixelRatio || 1;
        this.context.scale(resolution, resolution);

        // update canvas size to match CSS and to incorporate resolution
        this.canvas.width = this.canvas.clientWidth * resolution;
        this.canvas.height = this.canvas.clientHeight * resolution;

        // use these sizes for plotMapCirclesting
        this.width = this.canvas.width;
        this.height = this.canvas.height;

        // plot
        this.plotMap();
      },
      filterDataByDate(date) {
        let filteredData = Object.assign({}, this.data); 

        // filter thor data to only items with those dates
        let filteredThorData = this.data.thor.filter( x => {
          return x.msnDate <= date
        })
        
        // if bars, get cumulative values
        if (this.plotType === 'bars') {
          filteredThorData = this.getDataByLocation(filteredThorData);
        }

        // assign
        filteredData.thor = filteredThorData;
        this.filteredData = filteredData;
      },
      getDataByLocation(data) {

        // get dict of locations and tons in one loop
        let locationData = {};
        data.forEach( x => {
          if (!Object.keys(locationData).includes(x.destId)) {
            locationData[x.destId] = {
              tons: 0,
              destLat: x.destLat,
              destLon: x.destLon
            };
          }
          locationData[x.destId].tons += x.tons;
        })

        // turn dict into array of dicts
        return Object.values(locationData)
      },
      plotMap() {
        if (this.dataIsReady) {
          this.updateProjection();
          this.updatePath();
          plot(this.canvas, this.context, this.projection, this.path, this.filteredData, this.plotType, this.isMobile);
        }
      },
      updateProjection() {

        const scale = this.width / this.height > .85 ? this.height * 2.75: this.width * 2.75 / .85;

        this.projection = geoMercator()
          .center([137.5, 38.5])
          .scale(scale)
          .translate([ this.width/2, this.height/2 ])
      },
      updatePath() {
        this.path = geoPath()
          .projection(this.projection)
          .context(this.context)
      }
    }
  }
</script>