import { scaleLinear } from 'd3-scale';
import { interpolateHcl } from 'd3-interpolate';

export function plot(canvas, context, projection, path, data, plotType, isMobile) {

  // clear the canvas
  context.clearRect(0, 0, canvas.width, canvas.height);

  // set resolution
  const resolution = window.devicePixelRatio || 1;


  // countries
  context.fillStyle = 'rgba(0,0,0,.05)';
  data.countries.forEach(x => {
    context.beginPath();
    path(x);
    context.closePath();
    context.fill();
  })

  // bombing missions - CIRCLES
  let scaleArea = scaleLinear()
    .domain([0, 1000])
    .range([0, 3000])
  function getRadius(x) {
    return Math.sqrt(scaleArea(x) / Math.PI)
  }
  if (plotType === 'circles') {
    context.fillStyle = 'rgba(230, 38, 22, .2)';

    data.thor.forEach(x => {
      const loc = projection([x.destLon, x.destLat]);

      context.beginPath();
      context.arc(loc[0], loc[1], getRadius(x.tons), 0, 2 * Math.PI)
      context.fill();
      context.closePath();
    })
  }

  // bombing missions - BARS
  const barWidth = isMobile ? 7 : 5;
  const maxBarheight = isMobile ? 300 : 200;
  let scaleHeight = scaleLinear()
    .domain([0, 10000])
    .range([0, maxBarheight])
  if (plotType === 'bars') {
      const color0 = '#58508d'; //blue
      const color2 = '#dc3a16';
      let scaleColor = scaleLinear()
        .domain([0, 4000])
        .range([color0, color2])
        .interpolate(interpolateHcl);
      
    // shadows
    data.thor.forEach(x => {
      const loc = projection([x.destLon, x.destLat]);
      context.save();
      context.fillStyle = 'rgb(0,0,0,.1)';
      context.translate( loc[0]-barWidth/2, loc[1] );
      context.rotate( -.8 );
      context.translate( -1 * (loc[0] - barWidth/2), -1 *loc[1] )
      context.fillRect( loc[0]-barWidth/2, loc[1]-scaleHeight(x.tons)*.8, barWidth, scaleHeight(x.tons)*.8);      
      context.restore();
    })
    // bars
    data.thor.forEach(x => {
      const loc = projection([x.destLon, x.destLat]);
      context.fillStyle = x.tons <= 4000 ? scaleColor(x.tons) : color2;
      context.fillRect(loc[0]-barWidth/2, loc[1]-scaleHeight(x.tons), barWidth, scaleHeight(x.tons));
    })
  }


  // cities
  const cityArr = [{
    name: 'Tokyo',
    lon: 139.6503,
    lat: 35.6762,
    x: 100,
    y: 60,
    xOffset: 10,
    yOffset: 5*resolution,
    textAlign: 'left'
  }, {
    name: 'Osaka',
    lon: 135.5023,
    lat: 34.6937,
    x: 150,
    y: 90,
    xOffset: 10,
    yOffset: 5*resolution,
    textAlign: 'left'
  }]

  context.lineStyle = 'black';
  context.lineWidth = 1 * resolution;
  context.fillStyle = 'black';
  context.font = 16 * resolution + "px Oswald";

  cityArr.forEach( city => {
    context.beginPath();
    const loc = projection([city.lon, city.lat]);
    context.moveTo(loc[0] + city.xOffset, loc[1] + city.yOffset);
    context.lineTo(loc[0] + city.x, loc[1] + city.y);
    context.stroke();
    context.textAlign = city.textAlign;
    context.fillText(city.name, loc[0] + city.x + 2*city.xOffset, loc[1] + city.y + 2*city.yOffset);
    context.closePath();
  })


  // legend
  context.fillStyle = 'black';
  context.font = 16 * resolution + "px Oswald";
  context.textBaseline = 'middle';

  const x = 30 * resolution;
  const y = 30 * resolution;

  // circles
  if (plotType === 'circles') {
    const circles = [
      [1000, '1,000 tons', 0], 
      [500, '500 tons', 45],
      [100, '100 tons', 85]
    ];

    circles.forEach(circle => {
      const amt = circle[0];
      const text = circle[1];
      const yOffset = circle[2] * resolution;
      const radius = getRadius(amt);

      context.beginPath();
      context.fillStyle = 'rgba(230, 38, 22, 1)';
      context.arc(x, y + yOffset, radius, 0, 2 * Math.PI);
      context.fill();
      context.closePath();
      context.fillStyle = 'black';
      context.textAlign = 'left';
      context.fillText(text, x + 50, y + yOffset);

    })
  }

  // bars
  if (plotType === 'bars') {
      context.fillStyle = 'black';
      context.textAlign = 'left';
      
      const xOffset = 20 * resolution;
      const yOffset = scaleHeight(10000);

      context.fillText('10,000 tons', x + xOffset, y);
      context.fillText('0 tons', x + xOffset, y+yOffset)
    
      let grd = context.createLinearGradient(0, 0, 0, yOffset);
      grd.addColorStop(.8, '#dc3a16');
      grd.addColorStop(1, '#58508d');
      context.fillStyle = grd;
      context.fillRect(x, y, barWidth, yOffset);
  }
}

