<template>
  <div class='bar-chart-cities'>
    <table class='table'>
      <thead>
        <tr class='table__row is-uppercase'>
          <td>City</td>
          <td v-if='!isMobile'>U.S. Equivalent</td>
          <td v-if='!isMobile'>Population</td>
          <td>Percent Destroyed</td>
        </tr>
      </thead>

      <tbody>
        <tr v-for='(d, i) in data.arnold' :key='i' class='table__row'>
          <td class='table__col1'>{{ d.japanCity }}</td>
          <td v-if='!isMobile' class='table__col2'>{{ d.usCity }}</td>
          <td v-if='!isMobile' class='table__col3'>{{ formatNum(d.pop) }}</td>
          <td class='table__col4'>
            <div class='table__bar__text'>{{ parseInt(100*d.pct) }}%</div>
            <div class='table__bar__bar' :style="{width: d.pct*100 + '%'}">.
            </div>
            <div class='table__bar__bg' :style="{width: (100-d.pct*100) + '%'}">.
            </div>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>


<script>
  import { fmtNum } from '../js/helpers.js';

  export default {
    props: {
      data: Object,
      resize: Number,
      isMobile: Boolean
    },
    data() {
      return {
        rowHeight: 20,
        rowBuffer: 2,
      }
    },
    methods: {
      formatNum(x) {
        return fmtNum(x)
      }
    }
  }
</script>