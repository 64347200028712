export function fmtNum(x) { 
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
}

export function getDateArray(start, end) {
  let
    arr = new Array(),
    dt = new Date(start);
  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
}

